import React from "react";
import { Typography, Card, CardActions, CardContent, CardMedia, Button } from "@mui/material";
import "./HomePageCards.css";

export const PageUpdatesInfo = () => {
  return (
    <Card sx={{minWidth: 400, margin: 2 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Latest Website Updates
          </Typography>
        </CardContent>      
      <CardMedia
        component="img"
        alt="belt"
        height="240"
        image="https://static.wixstatic.com/media/c1fd03_6811941b2b4c4aab9fef0f19be0fbcfb~mv2.jpg/v1/fill/w_954,h_465,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/c1fd03_6811941b2b4c4aab9fef0f19be0fbcfb~mv2.jpg"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Coming Soon
            <Typography gutterBottom variant="subtitle2" component="div">
              25 Aug 2024 
            </Typography>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Welcome to American Boxing Organization boxer record keeper website. In the future you will be able to sign up with us as promoters, matchmakers, organizations, trainers or boxers.
          Our new age approach of usin AI driven match making engine, create events, and comissioners dashboards to updated boxin bouts results in real time is next level and next to none.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
  );
};
  
export const EventsInfo = () => {
    return (
      <Card sx={{ minWidth: 500, margin: 2 }}>
        <CardMedia
          component="img"
          alt="events"
          height="400"
          image="assets/images/headers/events.jpeg"
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            Keep an out here for future events
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">Learn More</Button>
        </CardActions>
      </Card>
    );
  }
  
export const FeaturedFighters = () => {
    return (
      <Card sx={{ minWidth: 500, margin: 2 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Featured Fighters
          </Typography>
        </CardContent>
        <CardMedia
          component="img"
          alt="place holder for featured Fighter"
          height="400"
          image="assets/images/headers/featuredFighterPlace.jpeg"
        />
        <CardActions>
          <Button size="small">Coming Sooon</Button>
        </CardActions>
      </Card>
    );
  }
  
export const AboNewsPaper = () => {
    return (
      <Card sx={{ minWidth: 500, margin: 2 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            ABO News Paper
          </Typography>
        </CardContent>
        <CardContent>
          <div className="videoWrapper">
            <iframe width="560" height="300" src="https://enewspaper.makemynewspaper.com/embed/515795/2708f78c923285/2024-08-18-aug-2024-02-2264/8/565311_1/3300/4200"></iframe>
          </div>
        </CardContent>
        <CardActions>
          <Button size="small">Coming Soon</Button>
        </CardActions>
      </Card>
    );
  }
  