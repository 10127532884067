import React from "react";
import { ThemeProvider, Typography, CssBaseline, Grid } from "@mui/material";
import { useDarkMode } from "../../hooks/useDarkMode";
import { lightTheme, darkTheme } from "../../themes/theme";
import { useTranslation } from "react-i18next";
import { MenuBarLoginAdminDesktop, MenuBarLoginAdminMobile } from "../../components/MenuBar/MenuBarLoginAdmin";
import { Footer } from "../../components/MenuBar/MenuBar";
import "./LoginDashboard.css";
import { menuHook } from "../../hooks/menuHook";
import { WelcomeAdmin } from "../../components/LoginDashboard/WelcomeAdmin";
import { AddUserCard } from "../../components/AddUserCard/AddUserCard";
import { AddOrganizationCard } from "../../components/AddOrganizationCard/AddOrganizationCard";
import { ViewOrganizationsTable } from "../../components/ViewOrganizationsTable/ViewOrganizationsTable";
import { AddBoxerCard } from "../../components/AddBoxerCard/AddBoxerCard";
import { ViewBoxersTable } from "../../components/ViewBoxersTable/ViewBoxersTable";
import { ChangePasswordCard } from "../../components/ChangePasswordCard/ChangePasswordCard";
import { useMediaQuery } from '@mui/material';

const LoginDashboardAdmin: React.FC = () => {
  const [ darkMode, setDarkMode ]: any = useDarkMode();
  const { t, i18n } = useTranslation();
  const theme = darkMode ? darkTheme : lightTheme;
  const [ menuItem, setMenuItem ] = menuHook();
  console.log("menuItem: ", menuItem)
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const isMobile = useMediaQuery('(max-width:1024px)');

  return (
    <ThemeProvider theme={theme}>
      { isDesktop && <MenuBarLoginAdminDesktop /> }
      { isMobile && <MenuBarLoginAdminMobile /> }

      <div>
        <CssBaseline />
        <Grid container alignContent="center" alignItems="center" direction="column">
          { menuItem === "WelcomeAdmin" && <WelcomeAdmin /> }
          { menuItem === "AddUserCard" && <AddUserCard /> }
          { menuItem === "AddOrganizationCard" && <AddOrganizationCard /> }
          { menuItem === "ViewOrganizationsTable" && <ViewOrganizationsTable />}
          { menuItem === "AddBoxerCard" && <AddBoxerCard /> }
          { menuItem === "ViewBoxersTable" && <ViewBoxersTable />}
          { menuItem === "ChangePasswordCard" && <ChangePasswordCard />}
        </Grid>
      </div>
    </ThemeProvider >
  );
};

export default LoginDashboardAdmin;
