import * as React from "react";
import { Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material/";
import { ABOClientApi } from "../../api/ABOApi";
import { userLoginHook, jwtHook } from "../../hooks/userHooks";
import CountrySelect from "./CountrySelect";
import { menuHook } from "../../hooks/menuHook";

export const AddUserCard = () => {
  const [ user, setUser ]: any = userLoginHook();
  const [ jwt, setJWT ]: any = jwtHook();
  const [ userInfo, setUserInfo] = React.useState({ username: "", password: "", user_firstname: "", user_lastname: "", user_email: "", user_phone: "",  user_address: "", user_city: "", user_state: "", user_postalcode: "", user_country: "", user_role: "", organization_name: ""});
  const [ status, setStatus ] = React.useState("");
  const [ orgs, setOrgs ] = React.useState([]);
  const [ menuItem, setMenuItem ] = menuHook();

  const getOrgs = async () => {
    try {
      const response: any = await ABOClientApi.get_all_organizations();
      console.log("get organizations response", response);
      setOrgs(response);
    } catch (error) {
      console.log("get organizations Error", error);
    };
  };

  const generateRandomChars = (): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    
    for (let i = 0; i < 6; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }
    console.log("random password", result);
    return result;
};

  const addUser = async () => {
    const password = generateRandomChars();
    const validate: any = await validateUserInfo();
    console.log("validate", Object.keys(validate).length);
    Object.keys(validate).length > 0 && setStatus(Object.values(validate).join(', '));
    if(Object.keys(validate).length > 0)return;
    try {
      setStatus("Adding User...");
      const response = await ABOClientApi.create_user(jwt, userInfo.user_email, password, userInfo.user_firstname, userInfo.user_lastname, userInfo.user_email, userInfo.user_phone, userInfo.user_address, userInfo.user_city, userInfo.user_state, userInfo.user_postalcode, userInfo.user_country, userInfo.user_role, userInfo.organization_name);
      setStatus(response);
      response === "ok" && setStatus(`Boxer ${userInfo.user_firstname} ${userInfo.user_lastname} Added.`)
    } catch (error) {
      console.log("registerOrg Error", error);
      setStatus(`Add User Error`);
    }
  };  

  const handleInputChange = (field: string, event: any) => {
    console.log("input change ", field, event )
    event.target.value === "add_org" && setMenuItem("AddOrganizationCard");
    setUserInfo({ ...userInfo,  [field]: event.target.value });
  };

  const handleCountryOriginChange = (event: any) => {
    setUserInfo({ ...userInfo, ["user_country"]: JSON.stringify(event) });
  };

  const SelectRole = () => {
    return(
      <FormControl sx={{ width: 200, margin: "5px", textAlign: "left", float: "left" }}>
        <InputLabel id="select_rolee_label">User Role</InputLabel>
        <Select
          labelId="select_role_label"
          id="select_role"
          value={userInfo.user_role}
          label="Select Role"
          onChange={(event: any) => handleInputChange("user_role", event)}
        >
          <MenuItem value="abo_admin">abo_admin</MenuItem>
          <MenuItem value="org_admin">org_admin</MenuItem>
          <MenuItem value="commissioner">commissioner</MenuItem>
          <MenuItem value="manager">manager</MenuItem>
          <MenuItem value="promoter">promoter</MenuItem>
          <MenuItem value="boxer">boxer</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const SelectOrganization = () => {
    return (
      <FormControl sx={{ width: 200, margin: "5px", textAlign: "left", float: "left" }}>
        <InputLabel id="SelectOrganization_label">Organization</InputLabel>
        <Select
          labelId="SelectOrganization_label"
          id="SelectOrganization"
          value={userInfo.organization_name}
          label="Organization"
          onChange={(event: any) => handleInputChange("organization_name", event)}
        >
          {
            orgs && orgs.map((org: any) => (
              <MenuItem value={org.organization_name}>{org.organization_name}</MenuItem>
            ))
          }
           <MenuItem value="add_org">Add new Org</MenuItem>

        </Select>
      </FormControl>
    );
  };

  React.useEffect(() => {
    getOrgs();
  }, []);


  const validateUserInfo: any = async () => {
    const errors: any = {};
    // !userInfo.username.trim() && (errors.username = 'Username is reuiqred');
    // !userInfo.password.trim() && (errors.password = 'Password is required');
    !userInfo.user_firstname.trim() && (errors.user_firstname = 'First name is required');
    !userInfo.user_lastname.trim() && (errors.user_lastname = 'Lastname is required');
    !userInfo.user_email.trim() && (errors.user_email = 'Email is required') ||
    !/\S+@\S+\.\S+/.test(userInfo.user_email) && (errors.user_email = 'Email address is invalid');
    !userInfo.user_phone.trim() && (errors.user_phone = 'Phone number is required') ||
    !/^\d{10}$/.test(userInfo.user_phone) && (errors.user_phone = 'Phone number is invalid');
    !userInfo.user_address.trim() && (errors.user_address = 'Address is required');
    !userInfo.user_city.trim() && (errors.user_city = 'City is required');
    !userInfo.user_state.trim() && (errors.user_state = 'State is required');
    !userInfo.user_postalcode.trim() && (errors.user_postalcode = 'Postal Code is required');
    !userInfo.user_country.trim() && (errors.user_country = 'Country is required');
    !userInfo.user_role.trim() && (errors.user_role = 'User Role is required');
    !userInfo.organization_name.trim() && (errors.organization_name = 'Organization name is required');
    return errors;
  };

  return (
    <>
    <div style={{maxWidth: 900, textAlign: "center"}}>
      <Typography variant="h5">Add New User</Typography>
      <hr />
      <form>
        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="User login Name"
          variant="outlined"
          value={userInfo.user_email}
          disabled
          // onChange={(event: any) => handleInputChange("username", event)}
        />

        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="User Temp Password"
          variant="outlined"
          value={userInfo.password}
          disabled
          // onChange={(event: any) => handleInputChange("password", event)}
        />

        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="User Firstname"
          variant="outlined"
          value={userInfo.user_firstname}
          onChange={(event: any) => handleInputChange("user_firstname", event)}
        />

        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="User Lastname"
          variant="outlined"
          value={userInfo.user_lastname}
          onChange={(event: any) => handleInputChange("user_lastname", event)}
        />

        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="User Email"
          variant="outlined"
          value={userInfo.user_email}
          onChange={(event: any) => handleInputChange("user_email", event)}
          
        />

        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="User Phone"
          variant="outlined"
          value={userInfo.user_phone}
          onChange={(event: any) => handleInputChange("user_phone", event)}
        />
        
        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="User Address"
          variant="outlined"
          value={userInfo.user_address}
          onChange={(event: any) => handleInputChange("user_address", event)}
        /> 

        <TextField 
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="User City"
          variant="outlined"
          value={userInfo.user_city}
          onChange={(event: any) => handleInputChange("user_city", event)}
        />

        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="User State"
          variant="outlined"
          value={userInfo.user_state}
          onChange={(event: any) => handleInputChange("user_state", event)}
        />

        <TextField 
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="User Postal Code"
          variant="outlined"
          value={userInfo.user_postalcode}
          onChange={(event: any) => handleInputChange("user_postalcode", event)}
        />

        <CountrySelect handleInputChange={handleCountryOriginChange} label="User Country" />
        
        <SelectOrganization />

        <SelectRole />
        
      </form>
    </div>
    <div>
      <Button variant="contained" color="primary" onClick={ ()=>addUser() }>
        Add User
      </Button>
    </div>
    { status && <div><Typography variant="h5">{status}</Typography></div> }
    </>
  );
};