import React from "react";
import { NavLink } from "react-router-dom";
import { AppBar, Toolbar, Typography, Tooltip, Button, Box, Menu, MenuItem } from "@mui/material";
import { useDarkMode } from "../../hooks/useDarkMode";
import Brightness3Icon from "@mui/icons-material/Brightness3";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import { useTranslation } from "react-i18next";
import LanguageMenu from "../../containers/LanguageMenu";
import { menuHook } from "../../hooks/menuHook";
import { useNavigate } from "react-router-dom";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuIcon from '@mui/icons-material/Menu';

export const MenuBarLoginAdminDesktop: React.FC = () => {
  const [ menuItem, setMenuItem ]: any = menuHook();
  return (
    <AppBar position="static">
      <Toolbar >
        <Box sx={{ display: "flex"}}>
          <NavLink to="/" ><img className="logo" alt="logo" width="100" src="assets/images/ABOLogo.jpg" /></NavLink>
        </Box>
        <Box sx={{ flexGrow: 1, marginLeft: "5px", alignItems:"top" }}>
          <Typography variant="h6" component="div" sx={{ display: "flex" }}>American Boxing Organization Boxer Record Keeper</Typography>
        </Box>
        <Box sx={{ display: "flex", marginRight: "50px" }}>
          <Button variant="outlined" color="primary" onClick={ ()=>setMenuItem("WelcomeAdmin") } >
            Home
          </Button>
          <UsersMenu />
          <OrgMenu />
          <CommissionerMenu />
          <BoxerMenu />
        </Box>
        <Box sx={{ display: "flex", marginRight: "50px" }}>
          <ManageAccountMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export const MenuBarLoginAdminMobile: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [ menuItem, setMenuItem ]: any = menuHook();
  return (
    <AppBar position="static">
      <Toolbar >
        <Box sx={{ display: "flex"}}>
          <NavLink to="/" ><img className="logo" alt="logo" width="100" src="assets/images/ABOLogo.jpg" /></NavLink>
        </Box>
        <Box sx={{ flexGrow: 1, marginLeft: "5px", alignItems:"top" }}>
          <Typography variant="h6" component="div" sx={{ display: "flex", fontSize: {
          xs: '.75rem',  // Extra small screens
          sm: '1rem', // Small screens
          md: '1.2rem', // Medium screens
          lg: '2rem',   // Large screens
          xl: '2.5rem', // Extra large screens
          }, }}>American Boxing Organization Boxer Record Keeper</Typography>
        </Box>
        <Button
          variant="outlined"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MenuIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          
        >
          <Box sx={{}}>
            <Button variant="outlined" color="primary" onClick={ ()=>setMenuItem("WelcomeAdmin") } sx={{width: "100%"}} >
              Home
            </Button>
            <UsersMenu />
            <OrgMenu />
            <CommissionerMenu />
            <BoxerMenu />
            <ManageAccountMenu />
          </Box>  
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

const UsersMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [ menuItem, setMenuItem ]: any = menuHook();
  return (
    <div>
      <Button
        sx={{width: "100%"}}
        variant="outlined"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Users
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>
          <Button variant="text" color="primary" onClick={ ()=>setMenuItem("AddUserCard") } >
            Add User
          </Button>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Button variant="text" color="primary" onClick={ ()=>setMenuItem("AddUserCard") } >
            View Users
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
}

const ManageAccountMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [ darkMode, setDarkMode ]: any = useDarkMode();
  const { t, i18n } = useTranslation();
  const [ menuItem, setMenuItem ]: any = menuHook();
  const navigate = useNavigate();

  const logout = () => {
    navigate("/LoginPage");
    sessionStorage.clear();
  };
  return (
    <div>
      <Button
        sx={{width: "100%"}}
        variant="outlined"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <ManageAccountsIcon />
      </Button>
      <Menu
      
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        
      >
        <MenuItem onClick={handleClose}>
          <Button variant="contained" color="primary" onClick={ ()=>setMenuItem("") } style={{flex: 1}}>
            My Profile
          </Button>
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <LanguageMenu />
        </MenuItem>
        
        <MenuItem onClick={handleClose}>
          <Tooltip title={t("Toggle Dark Mode")}>
            <Button variant="contained" style={{flex: 1}} onClick={ () => setDarkMode(darkMode ? false : true) }>
              {darkMode ? <Brightness3Icon /> : <WbSunnyIcon />}
            </Button>
          </Tooltip>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Button variant="contained" color="primary" onClick={ ()=>setMenuItem("ChangePasswordCard") } style={{flex: 1}}>
            Change Password
          </Button>
        </MenuItem>

        <MenuItem onClick={handleClose} >
          <Button variant="contained" onClick={ ()=>logout() } style={{flex: 1}}>
            Log Out
          </Button>
        </MenuItem>

      </Menu>
    </div>
  );
}

const OrgMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [ menuItem, setMenuItem ]: any = menuHook();
  return (
    <div>
      <Button
        sx={{width: "100%"}}      
        variant="outlined"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Organizations
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl} 
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>
          <Button variant="text" color="primary" onClick={ ()=>setMenuItem("AddOrganizationCard") } style={{flex: 1}}>
            Add Organization
          </Button>
        </MenuItem>
        <MenuItem onClick={handleClose}>  
          <Button variant="text" color="primary" onClick={ ()=>setMenuItem("ViewOrganizationsTable") }>
            View Organizations
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
}

const CommissionerMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [ menuItem, setMenuItem ]: any = menuHook();
  return (
    <div>
      <Button
        sx={{width: "100%"}}      
        variant="outlined"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Commissioners
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>
          <Button variant="text" color="primary" onClick={ ()=>setMenuItem("AddCommissionerCard") } >
            Add Commissioner
          </Button>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Button variant="text" color="primary" onClick={ ()=>setMenuItem("ViewCommissionersTable") }>
            View Commissioners
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
}

const BoxerMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [ menuItem, setMenuItem ]: any = menuHook();
  return (
    <div>
      <Button
        sx={{width: "100%"}}      
        variant="outlined"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Boxers
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>
          <Button variant="text" color="primary" onClick={ ()=>setMenuItem("AddBoxerCard") }>
            Add Boxer
          </Button>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Button variant="text" color="primary" onClick={ ()=>setMenuItem("ViewBoxersTable") }>
            View Boxers
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
}
