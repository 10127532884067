export default {
  // Moment integration
  "Date": "{{date, date}}",
  // App Bar
  "Home": "Home",
  "Change Language": "更改语言",
  "Toggle Dark Mode": "切换黑暗模式",
  "Configuration": "配置",
  "Back": "返回",
};
