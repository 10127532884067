import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper, Box, Typography, Modal } from '@mui/material';
import { ABOClientApi } from "../../api/ABOApi";
import moment from 'moment';

export const ViewOrganizationsTable = () => {

  const [rows, setRows]: any = React.useState([]);

  const getOrganizations = async () => {
    try {
      const response = await ABOClientApi.get_all_organizations();
      console.log(response);
      setRows(response);
    } catch (error) {
      console.log("getOrganizations Error", error);
    }
  };

  const formatDate = (date: any) => {
    console.log("date", date);
    return moment(date*1000).format('MMMM Do YYYY');
  };

  React.useEffect(() => {
    getOrganizations();
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Organization ID</TableCell>
            <TableCell align="center">Organization Logo</TableCell>
            <TableCell align="left">Organization Name</TableCell>
            <TableCell align="left">Organization Short</TableCell>
            <TableCell align="left">Organization Email</TableCell>
            <TableCell align="left">Organization Phone</TableCell>
            <TableCell align="left">Organization Country</TableCell>
            <TableCell align="left">Created</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <TableRow
              key={row.boxer_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">{row.organization_id}</TableCell>
              <TableCell align="center"><img src={row.org_image} alt="org logo" width="100"/></TableCell>
              <TableCell align="left">{row.organization_name}</TableCell>
              <TableCell align="left">{row.organization_shortname}</TableCell>
              <TableCell align="left">{row.organization_email}</TableCell>
              <TableCell align="left">{row.organization_phone}</TableCell>
              <TableCell align="left">{JSON.parse(row.organization_country).label}</TableCell>
              <TableCell align="left">{formatDate(row.created)}</TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


