/**
 * Converts a Base64 string to a Blob.
 * @param base64 - The Base64 string to convert.
 * @param mimeType - The MIME type of the Blob.
 * @returns - A Blob object.
 */
export const base64ToBlob = ( base64: string, mimeType: string | undefined): Blob => {
    const byteCharacters = atob(cleanBase64(base64));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mimeType });
};

/**
 * Converts a Blob to a Base64 string.
 * @param blob - The Blob to convert.
 * @returns - A Promise that resolves to a Base64 string.
 */
export const blobToBase64 = ( blob: Blob): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result as string);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};

export const cleanBase64 = (base64: string): string => {
    // Remove any characters that are not in the Base64 alphabet
    base64 = base64.replace(/[^A-Za-z0-9+/=]/g, '');

    // Ensure the length is a multiple of 4
    while (base64.length % 4 !== 0) {
        base64 += '=';
    }

    // Validate the Base64 string
    if (!/^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/.test(base64)) {
        throw new Error('Invalid Base64 string');
    }

    return base64;
};

export const blobToString = (blob: Blob): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            if (reader.result) {
                resolve(reader.result as string);
            } else {
                reject('Failed to convert Blob to string.');
            }
        };
        reader.onerror = () => {
            reject(reader.error);
        };
        reader.readAsText(blob);
    });
};

/**
 * Converts a string to a Blob.
 * @param str - The string to convert.
 * @param mimeType - The MIME type of the Blob.
 * @returns - A Blob object.
 */
export const stringToBlob = (str: string, mimeType: string): Blob => {
    const byteArrays = [];

    for (let offset = 0; offset < str.length; offset += 512) {
        const slice = str.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mimeType });
}

interface ImageSelectorProps {
    selectedImage: File | null;
    setSelectedImage: React.Dispatch<React.SetStateAction<File | null>>;
    base64Image: string;
    setBase64Image: React.Dispatch<React.SetStateAction<string>>;
    boxerInfo: any;
    setBoxerInfo: React.Dispatch<React.SetStateAction<any>>;
}

export const ImageSelector: React.FC<ImageSelectorProps> = ({ selectedImage, setSelectedImage, base64Image, setBase64Image, boxerInfo, setBoxerInfo }) => {
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0] || null;
        console.log("file: ", file);
        if (file) {
            setSelectedImage(file);
            convertToBase64(file);
        }
    };

    const convertToBase64 = (file: File) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setBase64Image(reader.result as string);
            setBoxerInfo({ ...boxerInfo, boxerImage: reader.result as string });
        };
    };

    return (
        <div>
            <input type="file" accept="image/*" onChange={handleImageChange} />
            {
              base64Image && (
                <div>
                  <img src={base64Image} alt="Selected" style={{ width: '200px' }} />
                </div>
            )
            /*
            {selectedImage && (
                <div>
                    <h2>Selected Image:</h2>
                    <img src={URL.createObjectURL(selectedImage)} alt="Selected" style={{ width: '200px' }} />
                </div>
            )}
            {base64Image && (
                <div>
                    <h2>Base64 Image:</h2>
                    <textarea readOnly rows={10} cols={50} value={base64Image} />
                </div>
            )}
          */}
        </div>
    );
};

