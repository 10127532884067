import React from "react";
import { AppBar, Toolbar, Typography, Button, Box, Tooltip, Menu, MenuItem } from "@mui/material";
import Grid from '@mui/material/Grid';
import { useDarkMode } from "../../hooks/useDarkMode";
import Brightness3Icon from "@mui/icons-material/Brightness3";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import { useTranslation } from "react-i18next";
import LanguageMenu from "../../containers/LanguageMenu";
import { NavLink } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';

export const MenuBarDesktop: React.FC = () => {
  const [ darkMode, setDarkMode ]: any = useDarkMode();
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <AppBar position="static">
      <Toolbar >
        <Box sx={{ display: "flex"}}>
          <NavLink to="/" ><img className="logo" alt="logo" width="100" src="assets/images/ABOLogo.jpg" /></NavLink>
        </Box>
        <Box sx={{ flexGrow: 1, marginLeft: "5px", alignItems:"top" }}>
          <Typography variant="h6" component="div" sx={{ display: "flex" }}>American Boxing Organization Boxer Record Keeper</Typography>
        </Box>
        <Box sx={{ display: "flex", marginRight: "50px" }}>

          <MenuItem>
            <NavLink to="/LoginPage" >
              <Button variant="contained" style={{flex: 1}} >
                Login
              </Button>
            </NavLink>
          </MenuItem>

          <MenuItem>
            <LanguageMenu />
          </MenuItem>
          
          <MenuItem>
            <Tooltip title={t("Toggle Dark Mode")}>
              <Button variant="contained" style={{flex: 1}} onClick={ () => setDarkMode(darkMode ? false : true) }>
                {darkMode ? <Brightness3Icon /> : <WbSunnyIcon />}
              </Button>
            </Tooltip>
          </MenuItem>
      </Box>
      </Toolbar>
    </AppBar>
  );
};

export const MenuBarMobile: React.FC = () => {
  const [ darkMode, setDarkMode ]: any = useDarkMode();
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <AppBar position="static">
      <Toolbar >
        <Box sx={{ display: "flex"}}>
          <NavLink to="/" ><img className="logo" alt="logo" width="100" src="assets/images/ABOLogo.jpg" /></NavLink>
        </Box>
        <Box sx={{ flexGrow: 1, marginLeft: "5px", alignItems:"top" }}>
          <Typography variant="h6" component="div" sx={{ display: "flex", fontSize: {
          xs: '.75rem',  // Extra small screens
          sm: '1rem', // Small screens
          md: '1.2rem', // Medium screens
          lg: '2rem',   // Large screens
          xl: '2.5rem', // Extra large screens
          }, }}>American Boxing Organization Boxer Record Keeper</Typography>
        </Box>
        <Button
          variant="outlined"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MenuIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          
        >
          <Box sx={{}}>
            <MenuItem>
              <NavLink to="/LoginPage" >
                <Button variant="contained" color="primary" >
                  Home
                </Button>
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink to="/LoginPage" >
                <Button variant="contained" style={{flex: 1}} >
                  Login
                </Button>
              </NavLink>
            </MenuItem>

            <MenuItem>
              <LanguageMenu />
            </MenuItem>
            
            <MenuItem>
              <Tooltip title={t("Toggle Dark Mode")}>
                <Button variant="contained" style={{flex: 1}} onClick={ () => setDarkMode(darkMode ? false : true) }>
                  {darkMode ? <Brightness3Icon /> : <WbSunnyIcon />}
                </Button>
              </Tooltip>
            </MenuItem>
          </Box>  
        </Menu>

      </Toolbar>
    </AppBar>
  );
};

export const Footer: React.FC = () => {
  const [ darkMode, setDarkMode ]: any = useDarkMode();
  const { t, i18n } = useTranslation();

  return (  
    <AppBar position="sticky" color="default" elevation={0}>
      <Toolbar>
        <Grid container alignContent="center" alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="caption">
              {t("Date", { date: new Date() })}
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>   
  );
};