import React from "react";
import { ThemeProvider, CssBaseline, Grid } from "@mui/material";
import { PageUpdatesInfo, EventsInfo, AboNewsPaper, FeaturedFighters } from "../../components/HomePageCards/HomePageCards";
import { useDarkMode } from "../../hooks/useDarkMode";
import { lightTheme, darkTheme } from "../../themes/theme";
import { MenuBarDesktop, MenuBarMobile } from "../../components/MenuBar/MenuBar";
import { useMediaQuery } from '@mui/material';
import "./Home.css";

const Home: React.FC = () => {
  const [ darkMode, setDarkMode ]: any = useDarkMode();
  const theme = darkMode ? darkTheme : lightTheme;
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const isMobile = useMediaQuery('(max-width:1024px)');

  return (
    <ThemeProvider theme={theme}>
      { isDesktop && <MenuBarDesktop /> }
      { isMobile && <MenuBarMobile /> }
      <div>
        <CssBaseline />
        <Grid container alignContent="center" justifyContent="left">
          <PageUpdatesInfo />
          <EventsInfo />
          <AboNewsPaper />
          <FeaturedFighters />
        </Grid>
      </div>  
    </ThemeProvider >
  );
};

export default Home;
