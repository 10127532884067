export default {
  // Moment integration
  "Date": "{{date, date}}",
  // App Bar
  "Home": "Home",
  "Change Language": "Change Language",
  "Toggle Dark Mode": "Toggle Dark Mode",
  "Configuration": "Configuration",
  "Back": "Back",
};
