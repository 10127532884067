import React from "react";
import { ThemeProvider, Typography, CssBaseline, Grid } from "@mui/material";
import { useDarkMode } from "../../hooks/useDarkMode";
import { lightTheme, darkTheme } from "../../themes/theme";
import { useTranslation } from "react-i18next";
import { MenuBarDesktop, MenuBarMobile } from "../../components/MenuBar/MenuBar";
import { useMediaQuery } from '@mui/material';
import { LoginCard } from "../../components/LoginCard/LoginCard";
import "./LoginPage.css";

const LoginPage: React.FC = () => {
  const [ darkMode, setDarkMode ]: any = useDarkMode();
  const { t, i18n } = useTranslation();
  const theme = darkMode ? darkTheme : lightTheme;
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const isMobile = useMediaQuery('(max-width:1024px)');

  return (
    <ThemeProvider theme={theme}>
      { isDesktop && <MenuBarDesktop /> }
      { isMobile && <MenuBarMobile /> }
      <div>
        <CssBaseline />
        <Grid container alignContent="center" alignItems="center" justifyContent="center" direction="column">
          <LoginCard />
          <Typography variant="caption" style={{ position: "absolute", bottom: "10px" }}>
            {t("Date", { date: new Date() })}
          </Typography>
        </Grid>
      </div>
    </ThemeProvider >
  );
};

export default LoginPage;
