import * as React from "react";
import { Typography, Card, CardActions, CardContent, CardMedia, Button } from "@mui/material/";
import { ABOClientApi } from "../../api/ABOApi";
import { userLoginHook } from "../../hooks/userHooks";

export const WelcomeAdmin = () => {

  const [ user, setUser ]: any = userLoginHook();
  console.log("admin user", JSON.parse(user));

  return (
    <div>
      <Typography variant="h5">Welcome back {JSON.parse(user).username}</Typography>
      <PageUpdatesInfo />
    </div>
  );
}

export const PageUpdatesInfo = () => {
  return (
    <Card sx={{ maxWidth: 500, margin: 2 }}>
      <CardMedia
        component="img"
        alt="belt"
        height="240"
        image="https://static.wixstatic.com/media/c1fd03_6811941b2b4c4aab9fef0f19be0fbcfb~mv2.jpg/v1/fill/w_954,h_465,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/c1fd03_6811941b2b4c4aab9fef0f19be0fbcfb~mv2.jpg"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Coming Soon
            <Typography gutterBottom variant="subtitle2" component="div">
              25 Aug 2024 
            </Typography>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Welcome to American Boxing Organization boxer record keeper website. In the future you will be able to sign up with us as promoters, matchmakers, organizations, trainers or boxers.
          Our new age approach of usin AI driven match making engine, create events, and comissioners dashboards to updated boxin bouts results in real time is next level and next to none.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
  );
};