import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper, Box, Typography, Modal } from '@mui/material';
import { ABOClientApi } from "../../api/ABOApi";
import moment from 'moment';

export const ViewBoxersTable = () => {

  const [rows, setRows]: any = React.useState([]);

  const getBoxers = async () => {
    try {
      const response = await ABOClientApi.get_all_boxers();
      console.log(response);
      setRows(response);
    } catch (error) {
      console.log("getBoxers Error", error);
    }
  };

  const formatDate = (date: any) => {
    console.log("date", date);
    return moment(date*1000).format('MMMM Do YYYY');
  };

  React.useEffect(() => {
    getBoxers();
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Boxer ABC ID</TableCell>
            <TableCell align="center">Boxer Image</TableCell>
            <TableCell align="left">First Name</TableCell>
            <TableCell align="left">Last Name</TableCell>
            <TableCell align="left">Sex</TableCell>
            <TableCell align="left">Date of Birth</TableCell>
            <TableCell align="left">Weight</TableCell>
            <TableCell align="left">Height</TableCell>
            <TableCell align="left">Reach</TableCell>
            <TableCell align="left">Stance</TableCell>
            <TableCell align="left">Division</TableCell>
            <TableCell align="left">Email Address</TableCell>
            <TableCell align="left">Phone Number</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <TableRow
              key={row.boxer_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.boxer_id}
              </TableCell>
              <TableCell align="center"><img src={row.boxer_image} alt="image of boxer" width="100"/></TableCell>
              <TableCell align="left">{row.boxer_firstname}</TableCell>
              <TableCell align="left">{row.boxer_lastname}</TableCell>
              <TableCell align="left">{row.boxer_gender}</TableCell>
              <TableCell align="left">{formatDate(row.boxer_dob)}</TableCell>
              <TableCell align="left">{row.boxer_weight}lbs</TableCell>
              <TableCell align="left">{row.boxer_height}in</TableCell>
              <TableCell align="left">{row.boxer_reach}in</TableCell>
              <TableCell align="left">{row.boxer_stance}</TableCell>
              <TableCell align="left">{row.boxer_division}</TableCell>
              <TableCell align="left">{row.boxer_email}</TableCell>
              <TableCell align="left">{row.boxer_phone}</TableCell>
              <TableCell align="left"><BoxerRecord boxerInfo={row}/></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


interface BoxerRecordsProps {
  boxerInfo: any;
};

export const BoxerRecord = ({boxerInfo}: BoxerRecordsProps) => {

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>Record</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {boxerInfo.boxer_firstname} has no bouts at this time.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Button variant="contained">Add Bout</Button>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}